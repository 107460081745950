import RenderBase from "./render-base.js";
import Api from "./api.js";

class RenderContactForm extends RenderBase {

    constructor(options, site, member) {
        super(options, site, {}, member);
    }

    #setDefaultValues(form) {
        const emailInput = form.querySelector('input[data-contact-email]');
        const nameInput = form.querySelector('input[data-contact-name]');

        if (emailInput) {
            emailInput.value = this.member ? this.member.email : this.options.memberEmail

            if (emailInput.value) {
                emailInput.readOnly = true
            }
        }
        if (nameInput) {
            nameInput.value = this.member ? this.member.name : ''
        }
    }

    #initRemainCharsControl(form) {
        const textarea = form.querySelector('textarea[data-contact-message]');
        const progressBar = form.querySelector('.outpost-progress-bar');
        const remChars = form.querySelector('.outpost-remaining-chars');

        function charCounter(inputField) {
            const maxLength = inputField.getAttribute("maxlength");
            const currentLength = inputField.value.length;

            const progressWidth = (currentLength / maxLength) * 100;
            progressBar.style.width = `${progressWidth}%`;
            remChars.style.display = "none";

            if (progressWidth <= 60) {
                progressBar.style.backgroundColor = "rgb(19, 160, 19)";
            } else if (progressWidth > 60 && progressWidth < 85) {
                progressBar.style.backgroundColor = "rgb(236, 157, 8)";
            } else {
                progressBar.style.backgroundColor = "rgb(241, 9, 9)";
                remChars.innerHTML = `${maxLength - currentLength} characters left`;
                remChars.style.display = "block";
            }
        }
        textarea.oninput = () => charCounter(textarea);
    }

    render() {
        // attach form submit event
        Array.from(document.querySelectorAll('form[data-outpost-contact-form]')).forEach(form => {
            const errorEl = form.querySelector('.outpost-message-error');
            const site = this.site;

            // set member details, if available
            this.#setDefaultValues(form)
            this.#initRemainCharsControl(form)

            const submitContactFormHandler = event => this.outpostContactFormSubmitHandler({ event, errorEl, form, site, submitContactFormHandler });
            form.addEventListener('submit', submitContactFormHandler);
        });
    }

    outpostContactFormSubmitHandler({ event, form, errorEl, site, submitHandler }) {
        const emailInput = event.target.querySelector('input[data-contact-email]');
        const nameInput = event.target.querySelector('input[data-contact-name]');
        const subjectSelect = event.target.querySelector('select[data-contact-subject]');
        const messageTextarea = event.target.querySelector('textarea[data-contact-message]');

        const email = emailInput?.value;
        const name = nameInput?.value;
        const subject = subjectSelect?.value;
        const message = messageTextarea?.value;

        form.removeEventListener('submit', submitHandler);
        event.preventDefault();

        this.resetFormClasses(form, errorEl);
        form.classList.add('loading');

        this.submitContactForm({ email, name, subject, message, form, errorEl, site, submitHandler });
    }

    submitContactForm({ email, name, subject, message, form, errorEl, site, submitHandler }) {
        const params = { email, name, subject, message };

        const api = new Api(this.options)
        api.request(
            '/contact-form',
            params,
            (response) => {
                form.addEventListener('submit', submitHandler);
                form.classList.remove('loading');

                if (response.success) {
                    form.classList.add('success');
                }
            }, () => {
                if (errorEl) {
                    errorEl.innerText = 'There was an error sending the email, please try again';
                }
                form.classList.remove('loading');
                form.classList.add('error');
            }
        );
    }

}

export default RenderContactForm
